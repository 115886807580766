import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const dataLink = [
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "این ویدیو به صورت مستند به زندگی و استراتژی‌های سرمایه‌گذاری وارن بافت، یکی از موفق‌ترین سرمایه‌گذاران تمام دوران، می‌پردازد.",
    title: "وارن بافت - بزرگترین سازنده پول دنیا",
  },
];
const LinksPreview = () => {
  return (
    <div className="links-preview">
      {dataLink.map((item, index) => (
        <>
          <div key={index} className="link-preview-item">
            <iframe
              //   width="300"
              //   height="200"
              src={item.youtubeLink}
              title={item.title}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="links-preview-content">
            <h3>{item.title}</h3>
            <div>  <p>{item.summary}</p>
            <p>{item.summary}</p>
            <p>{item.summary}</p>
            </div>
          
            <Link to="/links" className="white-btn btn">
              اطلاعات بيشتر{" "}
              <FontAwesomeIcon icon={faArrowLeft} className="icon" />
            </Link>
          </div>
        </>
      ))}
    </div>
  );
};

export default LinksPreview;
