import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img from "../../assets/Accountant-amico.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const SeniorAccountant = () => {
  return (
    <>
      <Navbar />
      <div className="job-container">
        <div className="job-content">
          <h2>کارشناس ارشد حسابداری</h2>
          <h3>شرح شغل و وظایف کارشناس ارشد حسابداری</h3>
          <p>
            {" "}
            با توجه به رابطه کاری تنگاتنگ با شرکای خارجی، آشنایی به مفاهیم
            پایهای حسابداری ارزی، از جمله ثبت اسناد تبدیلات ارزی، محاسبه و ثبت
            کارمزدهای عملیات خارجی، ثبت درآمدها و هزینهها به ارز و به ریال
            الزامی است.
          </p>
          <ul>
            <ol>نگهداری حساب های تن خواه گردان و هزینه‌های جاری</ol>
            <ol>مدیریت تمامی تراکنش‌های مالی و ثبت اسناد حسابداری مرتبط</ol>
            <ol>نگهداری و کنترل حساب‌های بانکی شرکت</ol>
            <ol>صدور و دریافت اسناد پرداختنی و دریافتنی</ol>
            <ol>رفع مغایرت‌های بانکی</ol>
            <ol>مدیریت ترازنامه و صورت های سود و زیان</ol>
            <ol>محاسبه حقوق و دستمزد و انجام امور مالیاتی و بیمه</ol>
          </ul>
          <p>
            ساعات کاری شنبه الی چهارشنبه 8:30 الی 17 و روزهای پنجشنبه 8:30 الی
            13 می باشد. حقوق به موقع، بیمه تامین اجتماعی، مزایای قانونی با این
            نگرش که همکاران شرکت در چهارچوب عرف حقوق و مزایای پرداختی برای این
            شغل می بایست بدور از دغدغه باشند، تا تمام توجه شان به ارایه کار خوب
            در یک محیط سالم کاری باشد.
          </p>
      
          <h3> شایستگی ها و مهارتهای فردی مورد نیاز:</h3>
          <ul>
            <ol> دارای مدرک تحصیلی مرتبط دارای</ol>
            <ol>
              {" "}
              نگرش سیستمی نظم کاری بالا و توجه به جزییات دارای حداقل 2 تا 5 سال
            </ol>
            <p>
              {" "}
              سابقه کار حسابداری تسلط کافی بر اصول و مفاهیم حسابداری و تسلط بر
              ثبت و طبقه بندی حسابها تسلط بر نرم افزارهای آفیس از جمله مهارت کار
              کردن با نرم افزار اکسل ضروری است بزرگترین مزیت شما برای اخذ و رشد
              در این شغل، تعهد کاری، مسئولیت پذیری بالا، به نتیجه رساندن وظایف
              محوله، و اشتیاق و توانایی بالا در یادگیری مفاهیم جدید می باشد.
            </p>
          </ul>
          <h3>محل کار: </h3>
          <p>
            خ ولیعصر، محدوده بین میدان ولیعصر تا میدان ونک (با دسترسی آسان به
            وسایل نقلیه عمومی)
          </p>
<button>

          <Link to="/careerform" className="blue-btn btn">
                    اطلاعات بیشتر
                  </Link>
</button>
        </div>
        <div className="job-img">
          <img src={img} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SeniorAccountant;
