import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const dataLink = [
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "این ویدیو به صورت مستند به زندگی و استراتژی‌های سرمایه‌گذاری وارن بافت، یکی از موفق‌ترین سرمایه‌گذاران تمام دوران، می‌پردازد.",
    title: "وارن بافت - بزرگترین سازنده پول دنیا",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "این ویدیو راهنمایی مناسبی برای مبتدیان برای شروع سرمایه‌گذاری را فراهم می‌کند، به بررسی مفاهیم و استراتژی‌های کلیدی برای کمک به تازه‌واردان در دنیای سرمایه‌گذاری می‌پردازد.",
    title: "چگونه پول خود را برای مبتدیان سرمایه‌گذاری کنید",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "این ویدیو مفهوم سود ترکیبی را شرح می‌دهد و اهمیت شروع زودهنگام به سرمایه‌گذاری را برای به حداکثر رساندن بازدهی در طول زمان بیان می‌کند.",
    title: "قدرت ترکیبی - سرمایه‌گذاری زودهنگام",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "این ویدیو توصیه‌ها و استراتژی‌های عملی برای مدیریت پول خود را برای دستیابی به آزادی مالی و امنیت ارائه می‌دهد.توضیحات خلاصه چهارم",
    title: "چگونه پول خود را برای آزادی مالی مدیریت کنید",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      " این ویدیو به بررسی جنبه‌های روانشناختی مدیریت پول می‌پردازد و نحوه رفتار ما تصمیمات مالی خود را تحت تأثیر قرار می‌دهد.",
    title: "روانشناسی پول",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      "در این ویدیو، ارائه‌دهنده بازار سهام را تجزیه و تحلیل می‌کند و راهنمایی‌هایی گام‌به‌گام برای مبتدیان برای درک نحوه سرمایه‌گذاری در سهام ارائه می‌دهد.",
    title: "سرمایه‌گذاری برای مبتدیان: 7 مرحله برای درک بازار سهام",
  },
  {
    youtubeLink: "https://www.youtube.com/embed/video_id_2",
    summary:
      " این ویدیو مفاهیم پایه‌ای سرمایه‌گذاری در املاک را پوشش می‌دهد، از چگونگی شروع، اشتباهات رایجی که باید از آنها پرهیز کرد و چگونگی املاک می‌تواند به دستیابی به استقلال مالی کمک کند.",

    title: "سرمایه‌گذاری در املاک 101: از شروع تا استقلال مالی",
  },
  // Add more data objects as needed
];

const Links = () => {
  return (
    <>
      <Navbar />
      <div className="links-component">
        <h2 className="links-header">بهترین دوره ها</h2>
        <div className="links-container">
          {dataLink.map((item, index) => (
            <div key={index} className="link-item">
              <h3>{item.title}</h3>
              <p>{item.summary}</p>
              <iframe
                width="300"
                height="200"
                src={item.youtubeLink}
                title={item.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Links;
