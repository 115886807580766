import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFax } from "react-icons/fa";

import img from "../../assets/Email campaign-amico.svg";
const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="contact-container">
        <h2>با ما در تماس باشید</h2>
        <div className="contact-content">
          <div className="contact-text">
            <ul>
                <ol> <FaMapMarkerAlt /> تهران، یوسف آباد، خیابان اکبری، نبش پله دوم، پلاک
          51، برج سرو ساعی، واحد 406</ol>
          <ol>
          <FaPhone /> 021-88719354
        </ol>
        <ol>
          <FaEnvelope /> info@mashreqzamin.com
        </ol>
        <ol>
          <FaFax /> 1433894575
        </ol>
            </ul>
            <a href="mailto:info@mashreqzamin.com">
          {" "}
          <button className="btn blue-btn backup">
            {" "}
            <p>پشتیبانی</p>
            <FaEnvelope className="icon" />
          </button>
        </a>
          </div>
          <div className="contact-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
