import React, { useState } from "react";
import img from "../../assets/signup/Hello-rafiki.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    // Here you can implement your login logic
    console.log("Username:", username);
    console.log("Password:", password);
  };

  return (
    <div className="login-page">
      <h3 className="red-text">سایت درحال بروزرسانی است، به زودی امکان ثبت نام و ورود فراهم می‌شود.</h3>
      <div className="login-container ">
        <div className="login-form">
          <h2>خوش آمدید!</h2>
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label>نام کاربری:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="User Name"
              />
            </div>
            <div className="input-group">
              <label>رمز عبور:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
              />
            </div>
            <p className=" input-group-btn">
              <Link to="/signup" className="blue-btn btn">
                ثبت نام
              </Link>
              <Link to="/login" className="blue-btn btn">
                ورود
              </Link>
            </p>
          </form>
        </div>
        <div className="login-img">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
