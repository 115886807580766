import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const NavbarLink = ({ links, className }) => {
  return (
    <div className={`navbar__links ${className}`}>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.link}>
              {link.label}{" "}
              {link.dropdownItems ? (
                <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
              ) : (
                " "
              )}
            </Link>
            {link.dropdownItems ? (
              <div className="dropdown">
                {link.dropdownItems.map((item, idx) => (
                  <Link to={item.link} key={idx}>
                    {item.label}
                  </Link>
                ))}
              </div>
            ) : (
              " "
            )}
          </li>
        ))}
      </ul>
      {className ? (
        <div className="menu-btn">
          <Link to="/signup" className="white-btn btn">
            ثبت نام
          </Link>
          <Link to="/login" className="blue-btn btn">
            ورود
          </Link>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default NavbarLink;
