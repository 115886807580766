import React, { useState } from "react";
import NavbarLink from "./NavbarLink";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const navData = [
    {
      label: "خانه",
      link: "/",
      dropdownItems: undefined,
    },
    {
      label: "فعالیت‌ها",
      link: "/activity",
      dropdownItems: [
        { label: "در جستجوی آلفا", link: "/alpha" },
        { label: "بروز رسانی پیوسته زیرساخت‌های فنی", link: "/" },
        { label: "تحلیل اقتصادی بازار‌ها", link: "/" },
      ],
    },
    {
      label: "سرمایه‌های انسانی",
      link: "/humanresources",
      dropdownItems: [
        { label: "فرصت‌های شغلی", link: "/career" },
        { label: " فضای کار ", link: "/workspace" },
        { label: "", link: "/" },
      ],
    },
    {
      label: "آموزش",
      link: "/links",
      dropdownItems: undefined,
    },

    {
      label: "مسئولیت اجتماعی",
      link: "/",
      dropdownItems: undefined,
    },
    { label: "درباره ما", link: "/about", dropdownItems: undefined },
    {
      label: "تماس با ما",
      link: "/contact",
      dropdownItems: undefined,
    },
  ];

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevMenuState) => !prevMenuState);
  };
  return (
    <nav className="navbar">
      {/* Sign Up and Login Buttons */}
      {/* Hamburger Menu */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className={`bar${isMenuOpen ? " animate" : ""}`}></div>
      </div>
      <div className={`navbar__buttons ${isMenuOpen ? "active" : ""}`}>
        <Link to="/signup" className="white-btn btn">
          ثبت نام
        </Link>
        <Link to="/login" className="blue-btn btn">
          ورود
        </Link>
      </div>
      {/* Links Component */}
      <NavbarLink links={navData} className={`${isMenuOpen ? "active" : ""}`} />
      {/* Logo Component */}
      <div className={`navbar__logo ${isMenuOpen ? "active" : ""}`}>
        <Link to="/">
          {/* <h3>NAVID</h3>{" "} */}
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
