// Footer.js
import React from "react";
import { Link } from "react-router-dom";
// import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFax } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="grid-container">
        <QuickLinks />
        <CompanyInfo />
        <FollowUs />
      </div>
    </footer>
  );
};

const QuickLinks = () => {
  return (
    <div className="grid-item">
      <h3>دسترسی سریع</h3>
      <div className="footer-content quick-links">
        <ul>
          <CustomLink to="/">خانه</CustomLink>
          <CustomLink to="/about">درباره ما</CustomLink>
          <CustomLink to="/alpha">در جستجوی آلفا</CustomLink>
          <CustomLink to="/contact">تماس با ما</CustomLink>
        </ul>
        <ul>
          <CustomLink to="/links">آموزش </CustomLink>
          <CustomLink to="/humanresources"> سرمایه‌های انسانی</CustomLink>
          <CustomLink to="/activity">فعالیت‌ها </CustomLink>
          <CustomLink to="/career">فرصت‌های شغلی </CustomLink>
        </ul>
      </div>
    </div>
  );
};

const CompanyInfo = () => {
  return (
    <div className="grid-item">
      <h3>نويد مشرق زمين</h3>
      <div className="footer-content company-info">
        <p>
          <FaMapMarkerAlt /> تهران، یوسف آباد، خیابان اکبری، نبش پله دوم، پلاک
          51، برج سرو ساعی، واحد 406
        </p>
        <p>
          <FaPhone /> 021-88719354
        </p>
        <p>
          <FaEnvelope /> info@mashreqzamin.com
        </p>
        <p>
          <FaFax /> 1433894575
        </p>
      </div>
    </div>
  );
};

const FollowUs = () => {
  return (
    <div className="grid-item">
      <h3>با ما در ارتباط باشید!</h3>
      <div className="footer-content ">
        <a href="mailto:info@mashreqzamin.com">
          {" "}
          <button className="btn blue-btn backup">
            {" "}
            <p>پشتیبانی</p>
            <FaEnvelope className="icon" />
          </button>
        </a>
      </div>
    </div>
  );
};

const CustomLink = ({ to, children }) => {
  return (
    <li>
      <Link to={to}>{children}</Link>
    </li>
  );
};

export default Footer;
