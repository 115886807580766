import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img from "../../assets/Resume folder-cuate.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const dataCareer = [
  { title: "توسعه‌دهنده‌ی FrontEnd  " , link:'/front'},
  { title: "توسعه‌دهنده‌ی BackEnd ", link:'/back' },
  { title: "کارشناس ارشد حسابداری  ", link:'/accountant' },
];
const Career = () => {
  const [resumeData, setResumeData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    resumeFile: null,
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resumeFile") {
      setResumeData({ ...resumeData, [name]: files[0] });
    } else {
      setResumeData({ ...resumeData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullName", resumeData.fullName);
    formData.append("email", resumeData.email);
    formData.append("phoneNumber", resumeData.phoneNumber);
    formData.append("resume", resumeData.resumeFile);
    // Now you can send formData to the server using fetch or any other method
    console.log("Form Data:", formData);
    // Optionally, you can reset the form fields after submission
    setResumeData({
      fullName: "",
      email: "",
      phoneNumber: "",
      resumeFile: null,
    });
  };
  return (
    <>
      <Navbar />
      <div className="career">
        <h1>فرصت‌های شغلی</h1>
        <div className="career-content">
          <div className="career-header">
            <h2>پیام مدیران و برخی کارمندان نوید مشرق زمین</h2>
            <p>
              بخش نظرات کارمندان نوید مشرق زمین، فرصتی است که به همه کارمندان شرکت
              می‌دهد تا نظرات، پیشنهادات و ایده‌های خود در مورد فعالیت‌های شرکت
              را به اشتراک بگذارند. این بخش به منظور ارتقای کیفیت کار و بهبود
              روابط داخلی در سازمان برای همه کارمندان باز است. همچنین شرکت، به
              نظرات کارمندان اهمیت و احترام بسیاری می‌گذارد و سعی می‌کند تا به
              بهبود و بهره‌وری کارمندان و کسب و کارش پی ببرد.
            </p>

            <h2>پست های سازمانی مورد نیاز نوید مشرق زمین</h2>
            <div className="career-needs-item">
              {dataCareer.map((item, index) => (
                <div key={index} className="career-item">
                  <Link to={item.link} className="blue-btn btn">
                    {item.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="career-form">
          <div className="career-form-content">
            <h2>ارسال رزومه برای بررسی بیشتر</h2>
            <form onSubmit={handleSubmit}>
              <p>
                <label>نام و نام خانوادگی</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={resumeData.fullName}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <label>ایمیل</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={resumeData.email}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <label> شماره تماس</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={resumeData.phoneNumber}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <p className="flex">
                  <label>رزومه خود را اینجا وارد کنید</label>

                  <input
                    placeholder="Resume"
                    type="file"
                    name="resumeFile"
                    onChange={handleInputChange}
                    className="blue-btn btn input-Resume"
                  />
                </p>
              </p>
              <button type="submit" className="blue-btn btn">
                ارسال
              </button>
            </form>
          </div>
          <div className="career-form-img">
            {" "}
            <img src={img} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
