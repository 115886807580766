import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img from "../../assets/About us page-cuate.svg";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="contact-container">
        <h2>درباره‌ی نوید مشرق زمین    </h2>
        <div className="contact-content">
          <div className="contact-text">
          <p >
              لورم ایپسوم(Lorem Ipsum) یک متن آزمایشی است که در صنعت چاپ و طراحی
              گرافیک استفاده می‌شود. این متن به طور معمول برای نمایش نمونه‌ای از
              طرح‌ها یا فضاهای متن‌خالی در سندی که در حال طراحی است، استفاده
              می‌شود. متن لورم ایپسوم از قطعاتی از کتاب‌های مختلف لاتین گرفته
              شده است و در این روزها در بیشتر موارد از نسخه‌هایی از آن استفاده
              می‌شود که توسط وبسایت‌ها و برنامه‌های کامپیوتری تولید شده است.
            </p>
          <p >
              لورم ایپسوم(Lorem Ipsum) یک متن آزمایشی است که در صنعت چاپ و طراحی
              گرافیک استفاده می‌شود. این متن به طور معمول برای نمایش نمونه‌ای از
              طرح‌ها یا فضاهای متن‌خالی در سندی که در حال طراحی است، استفاده
              می‌شود. متن لورم ایپسوم از قطعاتی از کتاب‌های مختلف لاتین گرفته
              شده است و در این روزها در بیشتر موارد از نسخه‌هایی از آن استفاده
              می‌شود که توسط وبسایت‌ها و برنامه‌های کامپیوتری تولید شده است.
            </p>
          
          </div>
          <div className="contact-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;

          