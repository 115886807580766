import React, { useState } from "react";
import img from "../../assets/signup/Financial data-amico.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData); // For demonstration purposes, log form data to the console
  };

  return (
    <div className="sign-up-page">
      <h3 className="red-text">سایت درحال بروزرسانی است، به زودی امکان ثبت نام و ورود فراهم می‌شود.</h3>
      <div className="sign-up-container">
        <div className="sign-up-form">
          <h2>خوش آمدید!</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">نام:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">نام خانوادگی:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">ایمیل:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">رمز عبور:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="Password"
              />
            </div>
            <p className=" input-group-btn">
              <Link to="/signup" className="blue-btn btn">
                ثبت نام
              </Link>
              <Link to="/login" className="blue-btn btn">
                ورود
              </Link>
            </p>
          </form>
        </div>
        <div className="sign-up-img">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
