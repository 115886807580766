
import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img from "../../assets/Low code development-amico.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const FrontendDeveloper = () => {
  return (
    <>
      <Navbar />
      <div className="job-container">
        <div className="job-content">
        <h2>توسعه‌دهنده‌ی FrontEnd</h2>
          <h3>شرح شغل و وظایف   توسعه‌دهنده‌ی FrontEnd</h3>
          <p>
          تیم IT شرکت نوید مشرق زمین به منظور تولید محصولات مورد نیاز شرکت در
            حوزه ی مالی، از زبان Javascript و فریم ورک Vue.js ،React و Vuetify ‌استفاده می کند، می‌توانید عضوی از تیم ما باشید.

          </p>
          <h3>شرح وظایف:</h3>
          <ul>
            <ol>تحلیل نیازمندی ها</ol>
            <ol>ارائه ی راهکارهای متناسب با نیازمندی ها</ol>
            <ol>برنامه نویسی و تست</ol>
          </ul>
          <p>
            ساعات کاری شنبه الی چهارشنبه 8:30 الی 17 و روزهای پنجشنبه 8:30 الی
            13 می باشد. حقوق به موقع، بیمه تامین اجتماعی، مزایای قانونی با این
            نگرش که همکاران شرکت در چهارچوب عرف حقوق و مزایای پرداختی برای این
            شغل می بایست بدور از دغدغه باشند، تا تمام توجه شان به ارایه کار خوب
            در یک محیط سالم کاری باشد.
          </p>
          <h3> شایستگی ها و مهارتهای فردی مورد نیاز:</h3>
          <ul>
            <ol> آشنایی با زبان Javascript و فریم ورک Vue.js ،React و Vuetify  
</ol>
            <ol>
            آشنایی با HTML و CSS و فریم ورک‌های آن از جمله SASS 

            </ol>
            <ol> تسلط بر GitHub و Git
</ol>
            <ol> تسلط بر حوزه User Interface و User Experience 
</ol>
            <ol>
            آشنایی با Figma و Sketch مزیت محسوب می‌شد.
            </ol>
          </ul>
          <h3>محل کار: </h3>
          <p>
            خ ولیعصر، محدوده بین میدان ولیعصر تا میدان ونک (با دسترسی آسان به
            وسایل نقلیه عمومی)
          </p>
<button>

          <Link to="/careerform" className="blue-btn btn">
                    اطلاعات بیشتر
                  </Link>
</button>
        </div>
        <div className="job-img">
          <img src={img} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FrontendDeveloper;
