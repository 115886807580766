import React, { useRef } from "react";
import AboutUsPreview from "./AboutUsPreview";
import LinksPreview from "./LinksPreview";
import slide01 from "../../assets/swiper/Business Plan-amico.svg";
import slide02 from "../../assets/At the office-amico.svg";
import slide03 from "../../assets/Growth curve-cuate.svg";
import slide04 from "../../assets/Finance-amico.svg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Home = () => {
  const data = [
    {
      title: "استراتژی‌های سرمایه گذاری، به سبک نوید",
      description: "سرمایه‌گذاری با درآمد: ایجاد جریان ثابت درآمد.",
      img: slide01,
      link: "/signup",
      linkLabel: "ثبت نام",
    },
    {
      title: "اسلاید 2",
      description:
        "این ویدیو راهنمایی مناسبی برای مبتدیان برای شروع سرمایه‌گذاری را فراهم می‌کند، به بررسی مفاهیم و استراتژی‌های کلیدی برای کمک به تازه‌واردان در دنیای سرمایه‌گذاری می‌پردازد.      متن اسلاید دوم لورم تست ",
      img: slide02,
      linkLabel: "۲ اطلاعات",
      link: "/",
    },
    {
      title: "اسلاید 3",
      description:
        "این ویدیو مفاهیم پایه‌ای سرمایه‌گذاری در املاک را پوشش می‌دهد، از چگونگی شروع، اشتباهات رایجی که باید از آنها پرهیز کرد و چگونگی املاک می‌تواند به دستیابی به استقلال مالی کمک کند.      متن اسلاید سوم لورم تست ",
      img: slide03,
      linkLabel: "۳ اطلاعات",
      link: "/",
    },
    {
      title: "اسلاید 4",
      description:
        "این ویدیو به بررسی جنبه‌های روانشناختی مدیریت پول می‌پردازد و نحوه رفتار ما تصمیمات مالی خود را تحت تأثیر قرار می‌دهد.      متن اسلاید چهارم لورم تست ",
      img: slide04,
      linkLabel: "اطلاعات ۴",
      link: "/",
    },
  ];

  const swiperRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goToPrev = () => {
    swiperRef.current.slickPrev();
  };

  const goToNext = () => {
    swiperRef.current.slickNext();
  };

  return (
    <>
      <Navbar />
      <div>
        <div className="swiper-container" style={{ maxWidth: "90vw" }}>
          <Slider {...settings} ref={swiperRef}>
            {data.map((item, index) => (
              <div className="swiper-slide" key={index}>
                <div className="swiper-slide-content">
                  <div className="swiper-slide-content-img">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <div className="swiper-slide-content-text">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <Link
                      to={item.link}
                      className="blue-btn btn swiper-btn"
                    >
                      {item.linkLabel}{" "}
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="icon"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="buttons">

        <button className="next-btn btn blue-btn" onClick={goToNext}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <button className="prev-btn btn blue-btn" onClick={goToPrev}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        </div>
        <AboutUsPreview />
        <LinksPreview />
      </div>
      <Footer />
    </>
  );
};

export default Home;
