import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

import SignUp from "./components/SignUp/SignUp";
import LoginPage from "./components/SignUp/LoginPage";
import Home from "./components/Home/Home";
import AboutUs from "./components/About/AboutUs";
import Links from "./components/Links/Links";
import Career from "./components/Career/Career";
import Careerform from "./components/Career/Careerform";
import Humanresources from "./components/Career/Humanresources";
import Activity from "./components/Activity/Activity";
import Alpha from "./components/Activity/Alpha";
import Workspace from "./components/Career/Workspace";
import FrontendDeveloper from "./components/Career/FrontendDeveloper";
import SeniorAccountant  from "./components/Career/SeniorAccountant";
import BackendDeveloper from "./components/Career/BackendDeveloper";
import Contact from './components/Contact/Contact'
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop />
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/links" element={<Links />} />
          <Route path="/career" element={<Career />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/careerform" element={<Careerform />} />
          <Route path="/humanresources" element={<Humanresources />} />
          <Route path="/alpha" element={<Alpha />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/" element={<Home />} />
          <Route path="/accountant" element={<SeniorAccountant  />} />
          <Route path="/back" element={<BackendDeveloper />} />
          <Route path="/front" element={<FrontendDeveloper />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
