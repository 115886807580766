import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
import 'aos/dist/aos.css';
const AboutUsPreview = () => {
  AOS.init();

  return (
    <div className="about-us-preview">
      <div className="about-us-preview-content">
        <div className="about-us-preview-grid-container">
          <div data-aos="fade-down" className="about-us-preview-grid-item">
            <p> ارائه راهکارهای سرمایه گذاری هوشمند و مبتنی بر تحلیل داده</p>
          </div>
          <div data-aos="fade-down" className="about-us-preview-grid-item">
            <p>
              ارائه تحلیل‌های مالی دقیق برای کمک به اتخاذ تصمیمات سرمایه گذاری
              بهتر
            </p>
          </div>
          <div data-aos="fade-down" className="about-us-preview-grid-item">
<p>طراحی و توسعه‌ی سیستم‌های نرم‌افزاری بازارهای مالی</p>          </div>
          <div  className="about-us-btn">
            <Link to="/about" className="white-btn btn">
              اطلاعات بيشتر
              {" "}
              <FontAwesomeIcon icon={faArrowLeft} className="icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="about-us-preview-title">
        <h2 className="section-preview-title">درباره ما</h2>
        <h1>About us</h1>
      </div>
    </div>
  );
};

export default AboutUsPreview;
