import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img from "../../assets/Resume-bro.svg";
import { Link } from "react-router-dom"; // Import Link from React Router

const Careerform = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    fatherName: "",
    nationalCode: "",
    email: "",
    phoneNumber: "",
    maritalStatus: "",
    birthDate: "",
    militaryServiceStatus: "",
    insuranceHistory: "",
    fieldOfStudy: "",
    address: "",
    position: "",
    companyName: "",
    employmentDuration: "",
    experienceCertificate: "",
    lastSalary: "",
    resumeFile: null,
  });

  const [showNextForm, setShowNextForm] = useState(false); // State to manage the visibility of the next form


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resumeFile") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);
    // Show the next form after submission
    setShowNextForm(true);
  };

  return (
    <>
      <Navbar />
      <div className="resume">
        <div className="resume-content">
          <div className="resume-img">
            <div className="resume-header">
              <h1>فرم پیوستن به تیم نوید مشرق زمین</h1>
              <p>
                برای پیوستن به تیم نوید مشرق زمین فرم زیر را به دقت تکمیل کرده و
                پس از ارسال منتظر تماس از تیم منابع انسانی ما باشید.
              </p>
            </div>
            <img src={img} alt="" />
          </div>
          <div className="resume-form">
          {showNextForm ? (
              <NextFormComponent formData={formData} handleChange={handleChange}/>
            ) : (

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstName">نام</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">نام خانوادگی</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="fatherName">نام پدر</label>
                <input
                  type="text"
                  className="form-control"
                  id="fatherName"
                  name="fatherName"
                  placeholder="Father's Name"
                  value={formData.fatherName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="nationalCode">کد ملی</label>
                <input
                  type="text"
                  className="form-control"
                  id="nationalCode"
                  name="nationalCode"
                  placeholder="National Code"
                  value={formData.nationalCode}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">ایمیل</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">شماره تماس</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="maritalStatus">وضعیت تاهل</label>
                <select
                  className="form-control direction custom-dropdown"
                  id="maritalStatus"
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange}
                >
                  <option value=""> </option>
                  <option value="مجرد">مجرد</option>
                  <option value="متاهل">متاهل</option>
                </select>
              </div>
             
              <div className="form-group">
                <label htmlFor="militaryServiceStatus">وضعیت نظام وظیفه</label>
                <select
                  className="form-control direction custom-dropdown"
                  id="militaryServiceStatus"
                  name="militaryServiceStatus"
                  value={formData.militaryServiceStatus}
                  onChange={handleChange}
                >
                  <option value=""> </option>
                  <option value="کارت پایان خدمت">کارت پایان خدمت</option>
                  <option value="معاف دائم">معاف دائم</option>
                  <option value="معاف موقت ">معاف موقت</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="insuranceHistory">سابقه بیمه</label>
                <input
                  type="text"
                  className="form-control"
                  id="insuranceHistory"
                  name="insuranceHistory"
                  placeholder="Insurance History"
                  value={formData.insuranceHistory}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="fieldOfStudy">رشته تحصیلی</label>
                <select
                  className="form-control direction custom-dropdown"
                  id="fieldOfStudy"
                  name="fieldOfStudy"
                  value={formData.fieldOfStudy}
                  onChange={handleChange}
                >
                  <option value=""> </option>
                  <option value="کاردانی  "> کاردانی</option>
                  <option value="کارشناسی ">کارشناسی </option>
                  <option value="کارشناسی ارشد  ">کارشناسی ارشد </option>
                  <option value="دکتری  ">دکتری </option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="address"> آدرس محل سکونت</label>
                <textarea
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form-group-flex">

              <div className="form-group">
                <label htmlFor="birthDate">تاریخ تولد</label>
                <input
                  type="date"
                  className="form-control custom-dropdown"
                  id="birthDate"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group Resume">
                <label>رزومه خود را اینجا وارد کنید</label>

                <input
                  placeholder=" Resume"
                  type="file"
                  name="resumeFile"
                  value={formData.resumeFile}
                  onChange={handleChange}
                  className="form-control "
                />
              </div>
              </div>
              <button type="submit" className="btn blue-btn">
                مرحله بعد
              </button>
            </form>    )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Careerform;

// Define your NextFormComponent here
const NextFormComponent = ({formData, handleChange }) => {
    return (
        <form>
        <div className="form-group">
          <label htmlFor="companyName">نام شرکت</label>
          <input
            type="text"
            className="form-control"
            id="companyName"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            
          />
        </div>
        <div className="form-group">
          <label htmlFor="employmentDuration">مدت همکاری</label>
          <input
            type="text"
            className="form-control"
            id="employmentDuration"
            name="employmentDuration"
            placeholder="Employment Duration"
            value={formData.employmentDuration}
            onChange={handleChange}
            
          />
        </div>
        <div className="form-group">
          <label htmlFor="reasonForLeaving">علت قطع همکاری</label>
          <input
            type="text"
            className="form-control"
            id="reasonForLeaving"
            name="reasonForLeaving"
            placeholder="Reason for Leaving"
            value={formData.reasonForLeaving}
            onChange={handleChange}
            
          />
        </div>
        <div className="form-group">
          <label htmlFor="experienceCertificate">امکان ارائه گواهی سابقه کار دارید؟</label>
          <select
            className="form-control direction custom-dropdown"
            id="experienceCertificate"
            name="experienceCertificate"
            value={formData.experienceCertificate}
            onChange={handleChange}
            
          >
            <option value=""> </option>
            <option value="بله">بله</option>
            <option value="خیر">خیر</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="lastSalary">آخرین حقوق دریافتی</label>
          <input
            type="text"
            className="form-control"
            id="lastSalary"
            name="lastSalary"
            placeholder="Last Salary"
            value={formData.lastSalary}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="position">سمت</label>
          <input
            type="text"
            className="form-control"
            id="position"
            name="position"
            placeholder="Position"
            value={formData.position}
            onChange={handleChange}
            required
          />
        </div>
        <Link to='/' type="submit" >
            <button className="btn blue-btn">ارسال</button>
          
        </Link>
      </form>
    );
  };